<template>
	<screen class="search-results-page" size="large">
		<guest-template
			slot="content"
			navHeaderTitle="Find a Nonprofit"
			:showNavFooter="false"
			:showNavHeader="true"
			routerPath="search"
		>
			<v-container fluid class="pa-0">
				<v-divider />
				<Search
					defaultWidth="580px"
					@search-results="handleSearchResults"
					:searchOnly="false"
					v-bind:institutions.sync="institutions"
					style="padding: 0 10px;"
					@error="handleError"
					:showMoreFilter="true"
					@no-results="handleNoResults"
					:defaultFilters="defaultFilters"
					@search-filters="setSearchFilters"
					@search-input="setSearchInput"
				/>
				<v-divider class="mb-5" />

				<SearchResultsList
					:totalResults="searchResults.nbHits"
					:totalPages="searchResults.nbPages"
					:currentPage="searchResults.page"
					:queryID="searchResults.queryID"
					v-bind:institutions.sync="institutions"
					@searchDetails="viewDetails"
					:loading="loading"
					:error="error"
					:noResults="noResults"
				/>
			</v-container>
		</guest-template>
	</screen>
</template>

<script>
import guestTemplate from '../../templates/guestTemplate.vue'
import { getUrlParams } from '../../../utilities'
import { mapGetters, mapActions } from 'vuex'
import Screen from '../../ui/Screen.vue'

export default {
	components: { guestTemplate, Screen },
	name: 'ResultsView',
	data: () => ({
		institutions: [],
		searchResults: {
			nbHits: 0,
			nbPages: 0,
			page: 0
		},
		defaultFilters: {
			category: null,
			subcategory: null,
			location: {
				city: null,
				state: null
			},
			zip: null,
			partner: null,
			query: null
		},
		loading: false,
		error: null,
		noResults: false
	}),
	methods: {
		...mapActions(['updateQuery', 'updateFilters']),
		viewDetails(inst) {
			// if npm.buildpipe === component
			// this.$root.$emit(inst.uuid)
			this.$router.push({
				name: 'search.details',
				props: { uuid: inst.uuid },
				params: { uuid: inst.uuid }
			})
		},
		handleLoading(v) {
			this.loading = v
		},
		handleError(v) {
			this.error = v
		},
		handleNoResults(v) {
			this.noResults = v
		},
		handleSearchResults(v) {
			this.searchResults = v
		},
		setSearchFilters(filters) {
			this.updateFilters(filters)
		},
		setSearchInput(query) {
			this.updateQuery(query)
		}
	},
	computed: {
		...mapGetters(['searchListQuery', 'searchListFilters'])
	},
	mounted() {
		this.$nextTick(() => {
			this.defaultFilters.query = this.searchListQuery
			this.defaultFilters = {
				...this.defaultFilters,
				...this.searchListFilters
			}
			this.$root.$on('loading', loading => (this.loading = loading))

			if (this.$route.params) {
				this.defaultFilters = {
					...this.defaultFilters,
					...this.$route.params
				}
			}
			const params = getUrlParams(window.location.hash)
			let paramKeys = Object.keys(params)
			const validParams = paramKeys.filter(
				param => Object.keys(this.$route.meta).includes(param) && param
			)
			if (validParams.length > 0) {
				for (const key of validParams) {
					this.defaultFilters[key] = params[key]
				}
			}
		})
	}
}
</script>
<style lang="scss" scoped>
.search-results-page {
	padding: 0px 0px 40px 0px !important;
	min-height: 95vh;
	.list-view {
		min-width: auto !important;
	}
}

.search-results-page::v-deep .screen-sub,
.search-results-page::v-deep .screen-body {
	display: none;
}
</style>
